<template>
  <!-- Footer start -->
  <div
    class="bg-black flex flex-col md:flex-row justify-center px-4 sm:px-10 py-10 md:px-[40px] md:py-[80px]"
  >
    <div
      class="flex flex-col gap-y-10 justify-start w-full md:w-[60%] max-w-[800px] text-center md:text-start"
    >
      <p class="text-white px-6 uppercase md:leading-10 font-acme">
        If you ever feel that gambling is becoming a
        <span class="text-[#AD8EE0]">problem</span>, we urge you to seek help
        and <span class="text-[#AD8EE0]">support</span>.
      </p>
      <p class="text-white px-6 uppercase md:leading-10 font-acme">
        Most people gamble for fun and enjoyment. However, some people think of
        gambling as a way to make money, spend more than they can afford, or use
        gambling to distract themselves from everyday problems.
      </p>

      <p class="text-white px-6 uppercase md:leading-10 font-acme">
        To find further advice, help and support visit:
        <a href="https://www.begambleaware.org/" class="text-[#AD8EE0]"
          >https://www.begambleaware.org/</a
        >
      </p>
    </div>
    <div>
      <div
        class="flex gap-x-[8vw] md:gap-x-6 lg:gap-x-10 justify-center items-start w-full md:w-[40vw] xl:w-[36vw] md:py-2 h-full px-6 md:px-0 mt-10 md:mt-0"
      >
        <a href="https://discord.com/invite/N4UMQS3aNN">
          <img
            src="../assets/discord.png"
            class="w-[30px] h-[30px]"
            alt="discord-icon"
          />
        </a>
        <a href="/">
          <img
            src="../assets/instagram.png"
            class="w-[30px] h-[30px]"
            alt="instagram-icon"
          />
        </a>
        <a href="/">
          <img
            src="../assets/youtube.png"
            class="w-[30px] h-[30px]"
            alt="youtube-icon"
          />
        </a>
        <a href="https://x.com/IFugroo">
          <img
            src="../assets/twitter.png"
            class="w-[30px] h-[30px]"
            alt="twitter-icon"
          />
        </a>
        <a href="https://kick.com/fugroo">
          <img
            src="../assets/kick.png"
            class="w-[30px] h-[30px]"
            alt="kick-icon"
          />
        </a>
      </div>
    </div>
  </div>

  <!-- Footer end -->
</template>
