<script>
import axios from "axios";
import moment from "moment-timezone";

export default {
  name: "LeaderboardSection",
  data() {
    return {
      top3: [],
      top7: [],
      top10: [],
      timer: "",
      loading: false,
      underMaintenance: false,
      startDate: "",
      endDate: "",
    };
  },
  methods: {
    init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;
      axios.get(`${baseUrl}/api/roobet/leaderboard`).then((res) => {
        const leaderboard = res.data.leaderboard;
        console.log(res.data);
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.top7 = leaderboard.slice(3, 10).map((item) => {
          return item;
        });

        this.top10 = leaderboard.slice(0, 10).map((item) => {
          return item;
        });
        this.startDate = res.data.startDate;
        this.endDate = res.data.endDate;
        this.loading = false;
      });
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
      const prizes = [200,100,75,50,25,25,15,10,5,5];
      // if (rank >= 4) {
      //   return 10;
      // }

      return prizes[rank];
    },
    startTimer() {
      let timerInterval;

      const updateTimer = () => {
        const nowUtc = moment.utc();

        // The end of the current month
        const endOfMonthUtc = moment
          .utc()
          .endOf("month")
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

        // The start of the next month (reset time)
        const nextMonthStartUtc = moment
          .utc()
          .add(1, "month")
          .startOf("month")
          .set({ hour: 8, minute: 0, second: 0, millisecond: 0 });

        if (
          nowUtc.isAfter(endOfMonthUtc) &&
          nowUtc.isBefore(nextMonthStartUtc)
        ) {
          // Display "waiting for reset" if between end of month and reset time
          this.timer = "Waiting for reset";
        } else {
          // Calculate the time until reset
          const targetDate = nowUtc.isBefore(nextMonthStartUtc)
            ? nextMonthStartUtc
            : nextMonthStartUtc.add(1, "month");
          const distance = targetDate.diff(nowUtc);

          if (distance <= 0) {
            this.timer = "Gathering new data";
            clearInterval(timerInterval);
            this.startTimer();
          } else {
            const duration = moment.duration(distance);
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
          }
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },

    getMaskedUsername(username) {
      return username.substring(0, 5) + "*****";
    },
  },
  mounted() {
    this.init();
    this.startTimer();
  },
};
</script>

<template>
  <!-- Leaderboard section start -->
  <section
    v-if="underMaintenance"
    class="flex flex-col justify-center items-center h-[500px] w-full bg-[#02002F] overflow-hidden md:pb-0 px-4 xs:px-6 sm:px-10 relative"
  >
    <div
      v-if="underMaintenance"
      class="font-roboto uppercase text-[14px] xs:text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px]"
    >
      <p>Under maintenance, be right back</p>
    </div>
  </section>

  <section
    v-if="!underMaintenance"
    class="flex flex-col justify-start bg-leaderboard-background bg-no-repeat bg-contain bg-top md:bg-center items-center h-full md:h-[1480px] lg:h-[1600px] xl:h-[1760px] w-full bg-[#02002F] overflow-hidden py-[60px] md:pb-0 px-4 xs:px-6 sm:px-10 relative"
  >
    <div
      class="flex flex-col justify-center items-center relative mt-0 md:mt-[60px] gap-y-10"
    >
      <h1
        class="text-white absolute -top-[30px] sm:-top-[26px] md:-top-[50px] lg:-top-[40px] xl:-top-[60px] text-[8px] xs:text-[10px] sm:text-[12px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-roboto uppercase"
        v-motion-pop-visible
      >
        **Need to wager $500 minimum to get leaderboard rewards
      </h1>
      <img
        src="../assets/leaderboard-header.png"
        class="w-[320px] xs:w-[440px] sm:w-[560px] md:w-[680px] lg:w-[900px] xl:w-[1200px]"
        alt="leaderboard-header"
        v-motion-pop-visible
      />
      <h1
        class="font-black-hans-sans text-center text-[10px] xs:text-[14px] sm:text-[18px] md:text-[22px] lg:text-[26px] xl:text-[30px]"
        v-motion-pop-visible
        v-if="loading == false"
      >
        All wagers are counted during the inclusive dates: <br />
        {{ startDate }} -
        {{ endDate }}
      </h1>
      <!-- <h1
        class="font-black-hans-sans text-[20px] md:text-[30px]"
        v-motion-pop-visible
      >
        Coming Soon....
      </h1> -->
      <p
        class="font-russo-one uppercase absolute text-[5px] tracking-[0.3em] top-[69px] left-[124px] xs:text-[7px] xs:left-[172px] xs:top-[96px] sm:text-[10px] sm:top-[121px] sm:left-[214px] md:text-[12px] md:top-[146px] md:left-[258px] lg:text-[16px] lg:top-[192px] lg:left-[342px] xl:text-[20px] xl:top-[256px] xl:left-[460px]"
        v-motion-pop-visible
      >
        {{ timer }}
      </p>
    </div>
    <div class="relative">
      <div
        v-if="loading == true"
        v-motion-pop-visible
        class="text-center text-white text-2xl md:text-4xl mt-10"
        style="font-family: Titan One, sans-serif"
      >
        Loading Leaderboard...
      </div>
      <img
        v-if="loading == false"
        src="../assets/leaderboard-table.png"
        class="hidden md:flex w-[330px] xs:w-[440px] sm:w-[600px] md:w-[680px] lg:w-[940px] xl:w-[1100px] mt-[100px]"
        alt="leaderboard-table"
        v-motion-slide-visible-left
      />

      <!-- Leaderboard Details -->

      <div
        v-if="loading == false"
        class="hidden md:flex overflow-x-hidden w-[260px] xs:w-[360px] sm:w-[570px] md:w-[560px] lg:w-[700px] xl:w-[900px] absolute top-0 left-[88px] xs:left-[110px] sm:left-[164px] md:left-[196px] lg:left-[234px] xl:left-[300px] mt-[100px]"
      >
        <table
          v-motion-slide-visible-left
          class="min-w-full h-[110px] xs:h-[156px] sm:h-[200px] md:h-[200px] lg:h-[310px] xl:h-[354px] bg-transparent font-saira-condensed"
        >
          <thead>
            <tr>
              <th
                class="px-4 text-left w-[30%] xs:w-[32%] sm:w-[26%] md:w-[26%] lg:w-[32%] xl:w-[18%]"
              ></th>
              <th
                class="px-4 text-left w-[22%] xs:w-[24%] sm:w-[16%] md:w-[22%] lg:w-[24%] xl:w-[18%]"
              ></th>
              <th class="px-4 text-left w-[130px] md:w-[40px] lg:w-[40%]"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="h-[30px] xs:h-[38px] sm:h-[5vh] md:h-[80px] lg:h-[70px]">
              <td
                class="text-[10px] xs:text-[20px] sm:text-[18px] md:text-[26px] lg:text-[44px] xl:text-[48px] font-bold uppercase text-center"
              >
                {{ top3.length > 0 ? getMaskedUsername(top3[0].username) : "" }}
              </td>
              <td
                class="text-[#5EDDE9] text-[10px] xs:text-[18px] sm:text-[18px] md:text-[26px] lg:text-[38px] xl:text-[42px] font-bold"
              >
                PRIZE: ${{ getRewardValue(0) }}
              </td>
              <td
                class="pl-[30px] xs:pl-[42px] sm:pl-[64px] md:pl-[68px] lg:pl-[94px] xl:pl-[110px] text-[7px] xs:text-[9px] sm:text-[12px] md:text-[18px] lg:text-[22px] xl:text-[30px] font-bold"
              >
                WAGERED: <br />
                <span
                  >${{
                    top3.length > 0 ? top3[0].weightedWagered.toFixed(2) : ""
                  }}</span
                >
              </td>
            </tr>
            <tr
              class="h-[30px] xs:h-[36px] sm:h-[5vh] md:h-[66px] lg:h-[70px] xl:h-[100px] text-[8px] xs:text-[7px] sm:text-[10px] md:text-[11px]"
            >
              <td
                class="text-[8px] xs:text-[16px] sm:text-[15px] md:text-[22px] lg:text-[36px] xl:text-[40px] font-bold uppercase text-center"
              >
                {{ top3.length > 0 ? getMaskedUsername(top3[1].username) : "" }}
              </td>
              <td
                class="text-[#5EDDE9] text-[8px] xs:text-[16px] sm:text-[15px] md:text-[22px] lg:text-[34px] xl:text-[34px] font-bold"
              >
                PRIZE: ${{ getRewardValue(1) }}
              </td>
              <td
                class="text-[6px] xs:text-[8px] sm:text-[10px] md:text-[14px] lg:text-[20px] xl:text-[22px] pl-[14px] xs:pl-[18px] sm:pl-[36px] md:pl-[36px] lg:pl-[50px] xl:pl-[54px] font-bold"
              >
                WAGERED: <br />
                <span
                  >${{
                    top3.length > 0 ? top3[1].weightedWagered.toFixed(2) : ""
                  }}</span
                >
              </td>
            </tr>
            <tr
              class="h-[28px] sm:h-[4.8vh] md:h-[80px] lg:h-[70px] xl:h-[80px]"
            >
              <td
                class="text-[7px] xs:text-[12px] sm:text-[15px] md:text-[20px] lg:text-[28px] xl:text-[38px] font-bold uppercase text-center"
              >
                {{ top3.length > 0 ? getMaskedUsername(top3[2].username) : "" }}
              </td>
              <td
                class="text-[#5EDDE9] text-[7px] xs:text-[10px] sm:text-[12px] md:text-[20px] lg:text-[26px] xl:text-[32px] font-bold"
              >
                PRIZE: ${{ getRewardValue(2) }}
              </td>
              <td
                class="pl-0 sm:pl-[4px] md:pl-[6px] lg:pl-0 xl:pl-[4px] md:text-[14px] lg:text-[18px] xl:text-[22px] font-bold text-start"
              >
                WAGERED: <br />
                <span class="pl-0"
                  >${{
                    top3.length > 0 ? top3[2].weightedWagered.toFixed(2) : ""
                  }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <!-- Ranks -->

      <div
        v-if="loading == false"
        class="flex flex-col justify-center items-center w-full mt-10"
      >
        <div class="w-full xs:w-[400px] sm:w-full bg-transparent">
          <table
            class="min-w-full sm:w-[540px] md:w-[700px] lg:w-[800px] xl:w-[1000px] bg-transparent font-saira-condensed"
          >
            <thead>
              <tr v-motion-slide-visible-bottom>
                <th
                  class="font-acme font-thin px-4 py-2 text-left w-[40vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                >
                  RANK
                </th>
                <th
                  class="font-acme font-thin px-4 py-2 text-left w-[40vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                >
                  USERNAME
                </th>
                <th
                  class="font-acme font-thin px-4 py-2 text-left w-[30vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                >
                  WAGERED
                </th>
                <th
                  class="font-acme font-thin px-4 py-2 text-left text-[14px] sm:text-[16px] md:text-[14px] lg:text-[16px]"
                >
                  PRIZE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in top7"
                :key="index"
                class="hidden md:table-row h-[50px]"
                v-motion-slide-visible-bottom
              >
                <td
                  class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                >
                  {{ getOrdinalSuffix(index + 4) }}
                </td>
                <td
                  class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                >
                  {{ getMaskedUsername(item.username) }}
                </td>
                <td
                  class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                >
                  ${{ item.weightedWagered.toFixed(2) }}
                </td>
                <td
                  class="font-saira-condensed font-bold text-[#5EDDE9] px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                >
                  ${{ getRewardValue(index + 3) }}
                </td>
              </tr>
              <tr
                v-for="(item, index) in top10"
                :key="index"
                class="table-row md:hidden h-[50px]"
                v-motion-slide-visible-bottom
              >
                <td
                  class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                >
                  {{ getOrdinalSuffix(index + 1) }}
                </td>
                <td
                  class="font-saira-condensed font-bold px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                >
                  {{ getMaskedUsername(item.username) }}
                </td>
                <td
                  class="font-saira-condensed font-bold px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                >
                  ${{ item.weightedWagered.toFixed(2) }}
                </td>
                <td
                  class="font-saira-condensed font-bold text-[#5EDDE9] px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                >
                  ${{ getRewardValue(index) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="flex flex-col justify-center items-start w-full bg-[#100E45] h-[200px] p-4 mt-8 text-[10px] xs:text-[10px] sm:text-[12px] md:w-[700px] md:text-[12px] lg:text-[14px] lg:w-[800px] xl:text-[16px] xl:w-[1000px]"
          v-motion-slide-visible-bottom
        >
          <p class="font-roboto uppercase">
            Your wagers on Roobet will count towards the leaderboard at the
            following weights based on the games you are playing. This helps
            prevent leaderboard abuse:
          </p>
          <div class="flex flex-col mt-4 uppercase">
            <p>
              *Games with an RTP under 96% will contribute 100% of the amount
              wagered to the leaderboard.
            </p>
            <p>
              *Games with an RTP of 97% will contribute 50% of the amount
              wagered to the leaderboard.
            </p>
            <p>
              *Games with an RTP of 98- 99% and above will contribute 10% of the
              amount wagered to the leaderboard.
            </p>
            <p>*Only house games and slots count towards leaderboard.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scope>
body {
  background-color: #1c1b1b;
}

.font-russo-one {
  font-family: "Russo One", sans-serif;
}

.font-black-hans-sans {
  font-family: "Black Han Sans", sans-serif;
}

.font-saira-condensed {
  font-family: "Saira Condensed", sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-acme {
  font-family: "Acme", sans-serif;
}
</style>
