<script>
export default {
  name: "SocialsSection",
};
</script>

<template>
  <!-- Leaderboard section start -->

  <section
    class="flex flex-col justify-start gap-y-2 md:gap-y-[80px] bg-[#100E45] items-center h-full w-full overflow-hidden py-[40px] md:py-[100px] px-8 xs:px-10 sm:px-10 md:px-[40px] xl:pl-[10vw] xl:pr-[4vw] pb-[60px] md:pb-[80px] relative"
  >
    <div class="flex flex-col md:flex-row gap-x-10 md:gap-x-8 xl:gap-x-12">
      <video
        src="../assets/socials-video.mp4"
        class="w-full md:w-[50vw] xl:w-[50vw] h-full"
        autoplay
        loop
        muted
        playsinline
        alt="socials-video"
        v-motion-pop-visible
      ></video>
      <div
        class="flex flex-col justify-center items-center gap-y-4 md:gap-y-6 xl:gap-y-[6vh] py-10 md:py-0"
      >
        <img
          src="../assets/socials-text-2.png"
          alt="JOIN ME WITH MY EPIC MOMENTS!"
          class="w-[440px] xs:w-[500px] sm:w-[540px] md:w-[40vw] lg:w-[44vw] xl:w-[32vw] h-auto"
          v-motion-pop-visible
        />
        <div class="flex flex-col gap-y-2">
          <a href="https://kick.com/fugroo">
            <img
              src="../assets/socials-text-3.png"
              alt="Catch Me Live On"
              class="w-[400px] xs:w-[500px] sm:w-[560px] md:w-[35vw] lg:w-[35vw] xl:w-[30vw] h-auto"
              v-motion-pop-visible
            />
          </a>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row justify-center items-center gap-y-10 xl:gap-x-[100px] w-full"
    >
      <div class="flex justify-center md:justify-start items-center w-full">
        <img
          src="../assets/socials-text-1.png"
          alt="Get Updated on My Socials"
          class="w-[300px] xs:w-[400px] sm:w-[540px] md:w-[50vw] lg:w-[50vw] xl:w-[50vw] h-auto max-w-none"
          v-motion-pop-visible
        />
      </div>
      <div class="flex justify-center items-center w-full xl:w-[1000px]">
        <div
          class="flex gap-x-10 justify-center items-center w-[240px] md:w-[280px] h-[100px] rounded-[100px] shadow-inner-dark bg-[linear-gradient(90deg,#15107B_0%,#1E17AE_50%,#15107B_100%)]"
          v-motion-pop-visible
        >
          <a href="https://kick.com/fugroo">
            <img
              src="../assets/kick.png"
              class="w-[30px] h-[30px]"
              alt="kick-icon"
            />
          </a>
          <a href="https://discord.com/invite/N4UMQS3aNN">
            <img
              src="../assets/discord.png"
              class="w-[30px] h-[30px]"
              alt="discord-icon"
            />
          </a>
          <a href="https://x.com/IFugroo">
            <img
              src="../assets/twitter.png"
              class="w-[30px] h-[30px]"
              alt="twitter-icon"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scope>
body {
  background-color: #1c1b1b;
}

.font-black-hans-sans {
  font-family: "Black Han Sans", sans-serif;
}

.font-saira-condensed {
  font-family: "Saira Condensed", sans-serif;
}

.font-acme {
  font-family: "Acme", sans-serif;
}

.shadow-inner-dark {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.8); /* Darker inner shadow */
}
</style>
