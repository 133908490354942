<template>
  <button
    class="md:hidden flex flex-col items-center justify-center w-8 h-12 rounded lg:hidden group focus:outline-none"
    @click="toggleMenu"
    v-motion-roll-visible-left
  >
    <div
      :class="[
        genericHamburgerLine,
        isOpen
          ? 'rotate-45 translate-y-2 group-hover:opacity-100'
          : 'group-hover:opacity-100',
      ]"
    ></div>
    <div
      :class="[
        genericHamburgerLine,
        isOpen ? 'opacity-0' : 'group-hover:opacity-100',
      ]"
    ></div>
    <div
      :class="[
        genericHamburgerLine,
        isOpen
          ? '-rotate-45 -translate-y-3 group-hover:opacity-100'
          : 'group-hover:opacity-100',
      ]"
    ></div>
  </button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HamburgerMenu",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    setIsOpen: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const genericHamburgerLine = `h-[2px] w-6 my-1 rounded-full bg-white transition ease transform duration-300`;

    const toggleMenu = () => {
      props.setIsOpen(!props.isOpen);
    };

    return {
      genericHamburgerLine,
      toggleMenu,
    };
  },
});
</script>
