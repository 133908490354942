<template>
  <!-- Navbar start -->
  <!-- <navbar-element v-if="loading == false"></navbar-element> -->
  <!-- Navbar end here-->

  <router-view></router-view>

  <!-- Footer start -->
  <footer-element v-if="loading == false"></footer-element>
  <!-- Footer end -->
</template>

<script>
import axios from "axios";
// import NavbarElement from '../components/NavbarElement.vue';
import FooterElement from "../components/FooterElement.vue";
export default {
  components: {
    FooterElement,
    // NavbarElement,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    const code = this.$route.query.code;
    if (code != null) {
      this.loading = true;
      let formData = {
        code: code,
      };
      axios
        .post(process.env.VUE_APP_SERVER_URL + "/auth/twitchLogin", formData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("oeg-access-token", res.data.accessToken);
            this.$store.commit("UPDATE_USER", res.data.accessToken);
            this.loading = false;
            this.$router.replace("/profile");
          }
        });
    }
  },
};
</script>

<style scoped></style>
