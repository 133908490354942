<script>
export default {
  name: "SocialsSection",
};
</script>

<template>
  <!-- Leaderboard section start -->

  <section
    class="flex flex-col justify-start items-center bg-challenges-background bg-no-repeat bg-contain bg-top md:bg-center h-[600px] md:h-[800px] w-full bg-[#02002F] overflow-hidden py-[60px] sm:py-[100px] md:py-[160px] md:pb-0 px-10 relative"
  >
    <div class="flex flex-col justify-center items-center gap-y-10">
      <img
        src="../assets/challenges-header.png"
        class="w-full md:w-[500px]"
        alt="leaderboard-header"
        v-motion-pop-visible
      />
      <h1
        class="font-black-hans-sans text-[20px] md:text-[30px]"
        v-motion-pop-visible
      >
        Coming Soon....
      </h1>
    </div>
  </section>
</template>

<style scope>
body {
  background-color: #1c1b1b;
}

.font-black-hans-sans {
  font-family: "Black Han Sans", sans-serif;
}

.font-saira-condensed {
  font-family: "Saira Condensed", sans-serif;
}

.font-acme {
  font-family: "Acme", sans-serif;
}

.shadow-inner-dark {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.8); /* Darker inner shadow */
}
</style>
