<script>
import { ref } from "vue";
import LeaderboardSection from "@/sections/LeaderboardSection.vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import SocialsSection from "@/sections/SocialsSection.vue";
import ChallengesSection from "@/sections/ChallengesSection.vue";
import PreviousLeaderboardSection from "@/sections/PreviousLeaderboardSection.vue";

export default {
  setup() {
    const menuOpen = ref(false);

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
    };

    return {
      menuOpen,
      toggleMenu,
    };
  },
  components: {
    LeaderboardSection,
    PreviousLeaderboardSection,
    SocialsSection,
    ChallengesSection,
    HamburgerMenu,
    MobileMenu,
  },
};
</script>

<template>
  <!-- Main section start -->

  <section
    class="h-full md:h-[640px] lg:h-[640px] xl:h-[640px] pt-[60px] xl:pt-0 bg-[#100E45] overflow-hidden pb-[60px] md:pb-0 relative"
  >
    <!-- Navbar section start-->

    <div
      class="flex fixed top-0 left-0 bg-opacity-50 justify-between items-center w-full py-2 md:pt-8 md:pb-6 px-6 md:px-[60px] bg-[#100E45] z-50"
    >
      <div>
        <img
          src="../assets/fugroo-logo.png"
          class="w-[120px]"
          alt="fugroo-logo"
          v-motion-slide-visible-right
        />
      </div>
      <div class="hidden md:flex gap-x-8 z-50" v-motion-slide-visible-left>
        <a
          href="#leaderboard"
          class="font-acme text-white font-bold focus:outline-none"
          >LEADERBOARD</a
        >
        <a
          href="#challenges"
          class="font-acme text-white font-bold focus:outline-none"
          @click="scrollToSection(challengesRef)"
          >CHALLENGES</a
        >
      </div>
      <HamburgerMenu :isOpen="menuOpen" :setIsOpen="toggleMenu" />
    </div>

    <!-- Mobile Menu -->
    <MobileMenu :isOpen="menuOpen" @toggleMenu="toggleMenu" />

    <!-- Hero Section start-->

    <div
      class="flex flex-col w-full h-full md:flex-row-reverse justify-start items-center px-4"
    >
      <!-- Desktop Hero Section -->

      <div
        class="hidden md:flex absolute top-[6%] lg:top-[6%] xl:top-[7%] h-full w-full z-10"
      >
        <img
          src="../assets/hero-overlay.png"
          class="w-full h-full lg:w-[2000px] object-center object-contain lg:object-cover xl:object-contain"
          alt="overlay"
          v-motion-pop-visible
        />
      </div>

      <div
        class="hidden md:flex z-40 absolute xl:hidden md:top-[250px] lg:top-[220px] xl:top-0 md:right-[60px] lg:right-[5%] xl:right-[7vw]"
      >
        <a
          href="https://roobet.com/?ref=fugroo"
          target="_blank"
          v-motion-pop-visible
        >
          <img
            src="../assets/fugroo-hero-logo.png"
            class="w-[30vw] md:w-[36vw] lg:w-[36vw] focus:outline-none"
            alt="fugroo-hero-logo"
          />
        </a>
      </div>

      <div
        class="flex flex-col justify-start xl:justify-center items-start xl:items-center z-40 absolute xl:hidden top-[260px] lg:top-[240px] left-[10vw] lg:left-[10vw]"
      >
        <a
          href="https://roobet.com/?ref=fugroo"
          target="_blank"
          v-motion-pop-visible
        >
          <img
            src="../assets/hero-text-1.png"
            class="hidden md:flex w-[30vw] md:w-[46vw] lg:w-[48vw] focus:outline-none"
            alt="fugroo-hero-logo"
          />
        </a>
      </div>

      <!-- XL screen size hero section -->

      <div
        class="hidden w-full xl:flex justify-center items-center z-40 mt-[100px]"
      >
        <a
          href="https://roobet.com/?ref=fugroo"
          target="_blank"
          class="focus:outline-none"
          v-motion-pop-visible
        >
          <img
            src="../assets/hero-text-1.png"
            class="w-[30vw] md:w-[32vw] lg:w-[400px] xl:w-[600px] focus:outline-none"
            alt="fugroo-hero-logo"
          />
        </a>
        <a
          href="https://roobet.com/?ref=fugroo"
          target="_blank"
          class="focus:outline-none"
          v-motion-pop-visible
        >
          <img
            src="../assets/fugroo-hero-logo.png"
            class="w-[30vw] md:w-[32vw] lg:w-[400px] xl:w-[400px]"
            alt="fugroo-logo"
          />
        </a>
      </div>

      <!-- Mobile Hero Section -->

      <div class="flex flex-col gap-y-[40px] md:hidden z-40 pt-[40px]">
        <a
          href="https://roobet.com/?ref=fugroo"
          target="_blank"
          v-motion-pop-visible
        >
          <img
            src="../assets/hero-logo-mobile.png"
            class="w-full"
            alt="fugroo-logo-mobile"
          />
        </a>
        <a href="https://roobet.com/?ref=fugroo" v-motion-roll-visible-bottom>
          <h1
            class="flex md:hidden flex-col uppercase text-center text-white font-extrabold font-black-hans-sans text-[5vw] md:text-[38px] lg:text-[4.8vw]"
          >
            Let's get you signed up with
            <span class="text-white"> code: fugroo</span>
          </h1>
        </a>
      </div>
    </div>
  </section>

  <section id="leaderboard">
    <LeaderboardSection />
  </section>

  <section id="previousLeaderboard">
    <PreviousLeaderboardSection />
  </section>

  <SocialsSection />

  <section id="challenges">
    <ChallengesSection />
  </section>
</template>

<style scope>
body {
  background-color: #1c1b1b;
}

.font-black-hans-sans {
  font-family: "Black Han Sans", sans-serif;
}

.font-acme {
  font-family: "Acme", sans-serif;
}
</style>
