<template>
  <div
    :class="[
      isOpen ? 'translate-x-[0%]' : 'translate-x-[-100%]',
      'w-[100vw] block flex-grow bg-[#100E45] h-[100vh] z-50 fixed top-0 py-[50px] lg:hidden transition',
    ]"
  >
    <div class="flex items-end justify-end mr-[50px]">
      <HamburgerMenu :isOpen="isOpen" :setIsOpen="toggleMenu" />
    </div>

    <div class="flex flex-col text-white w-full">
      <a
        href="#leaderboard"
        class="pl-[50px] hover-bg-slate-300 py-4 cursor-pointer"
        @click="toggleMenu"
      >
        Leaderboard
      </a>
      <!-- <a
        href="#previousLeaderboard"
        class="pl-[50px] hover-bg-slate-300 py-4 cursor-pointer"
        @click="toggleMenu"
      >
        Previous Leaderboard
      </a> -->
      <a
        href="#challenges"
        class="pl-[50px] hover-bg-slate-300 py-4 cursor-pointer"
        @click="toggleMenu"
      >
        Challenges
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";

export default defineComponent({
  name: "MobileMenu",
  components: {
    HamburgerMenu,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["toggleMenu"],
  setup(props, { emit }) {
    const toggleMenu = () => {
      emit("toggleMenu");
    };

    return {
      toggleMenu,
    };
  },
});
</script>
